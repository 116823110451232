import { asCiteprocItem } from '@readcube/readcube-citeproc'

import { bgBiblioProcess } from './workerCaller'

import { apaStyle } from './citeproc/style'
import { DocumentService } from '@readcube/smartcite-shared'
import { CitationMeta } from './citation'
import { chooseIdentApproach, injectRcTagsToStyle } from './identRefsInCitations'

export type GetScQueryItems = (collItemIdPairs: { ids: string[] }) =>
  ReturnType<typeof DocumentService.prototype.getBibliographyItems>

export type GetTypeSchema = (id: string) =>
  ReturnType<typeof DocumentService.prototype.getTypeSchema>

export type GetCustomFields = (id: string) =>
  ReturnType<typeof DocumentService.prototype.getCustomFields>

export interface BiblioResultFailure {
  success: false,
  payload: {
    reason: 'error' | 'mark_missing_items',
    items?: any[]
  }
}

export interface BiblioResultSuccess {
  success: true,
  payload: {
    biblioRefs: string[],
    biblioHtml: string[] | undefined,
    citations: Array<{
      meta: CitationMeta
      citationResult: any
      hasCslError: boolean
    }>,
  }
}

export interface Style {
  title: string,
  body: string,
}

export interface BiblioMeta {
  styleTitle: string,
  options?: {
    language?: string,
    isSectionsModeOn?: boolean
  }
}

export const genBiblioAndCitations = async (
  citationsMetas: CitationMeta[],
  getItems: GetScQueryItems,
  getMapping: GetTypeSchema,
  getCustomFields: GetCustomFields,
  options?: { style?: Style, locale?: string, useSpan?: boolean, transformToLinks?: boolean }
): Promise<BiblioResultFailure | BiblioResultSuccess> => {
  const citationsRefs =
    citationsMetas
      .map(meta => meta.refs)

  if (!citationsRefs || !citationsRefs.length)
    return { success: false, payload: { reason: 'error' } }

  const allItemRefs = (citationsRefs as any).flat()
  const uniqueItemRefs: any[] = [...new Set(allItemRefs)]
  const itemData = await getItems({ ids: uniqueItemRefs })

  if (!itemData)
    return { success: false, payload: { reason: 'error' } }

  if (!Array.isArray(itemData))
    return { success: false, payload: { reason: 'mark_missing_items', items: itemData.items } }

  const refCiteprocItemMap =
    itemData
      .reduce((all, item) => {

        if (!item)
          return all

        const ref = item.collection_id + ':' + item.id
        const mapping = getMapping(item.collection_id)

        const customFields = getCustomFields(item.collection_id);

        const citeprocItem = asCiteprocItem({ item, customSchema: mapping, customFields })

        citeprocItem.id = ref
        all[ref] = citeprocItem

        return all
      }, {})

  let style = options?.style?.body || apaStyle


  if (options.transformToLinks) {
    const identApproach = chooseIdentApproach({ style })

    if (identApproach === "inject_rc_identifiers_to_citation")
      style = injectRcTagsToStyle({ style: options.style.body })
  }

  const { biblio, citations } = await bgBiblioProcess({
    refCiteprocItemMap,
    citationsMetas,
    style,
    locale: options?.locale
  })

  let biblioHtml: string[] | undefined

  if (biblio)
    biblioHtml = reformatBiblioHtml(biblio, { useSpan: options?.useSpan })

  citations.forEach(setCslErrorFlagAndText)

  let biblioRefs: string[] = []
  const hasBiblioRefs = biblio
    && biblio[0]
    && biblio[0].entry_ids

  if (hasBiblioRefs)
    biblioRefs = biblio[0].entry_ids

  return { success: true, payload: { biblioHtml, biblioRefs, citations } }
}

const citeprocJsCslStyleError = '[CSL STYLE ERROR: reference with no printed form.]'

const setCslErrorFlagAndText = (citation: any) => {
  const hasCslError =
    citation
      ?.citationResult
      ?.includes(citeprocJsCslStyleError)

  if (hasCslError)
    citation.citationResult =
      citation
        .citationResult
        .replace(citeprocJsCslStyleError, 'METADATA ERROR DETECTED')

  citation = citation || {}
  citation.hasCslError = hasCslError
}

const reformatBiblioHtml = (citeprocBiblioResult, options?: { useSpan?: boolean }) => {

  const entryElem =
    options?.useSpan
      ? 'span'
      : 'p'

  const numericClass = 'csl-left-margin'
  const contentClass = 'csl-right-inline'

  const hasHangingIdent = citeprocBiblioResult[0].hangingindent
  const paragraphStyle =
    hasHangingIdent
      ? `style="margin-left: 20px; text-indent: -20px; display: inline-block;"`
      : ''

  const biblioHtmlString = citeprocBiblioResult[1].join('')
  const isNumericStyle =
    biblioHtmlString.includes(numericClass)
    && biblioHtmlString.includes(contentClass)

  const biblioHtml =
    new DOMParser()
      .parseFromString(biblioHtmlString, 'text/html')

  const cslEntries = Array.from(biblioHtml.querySelectorAll('.csl-entry'))

  const biblioHtmlStrings =
    cslEntries
      .map(cslEntryElem => {

        let entryHtml: string

        if (isNumericStyle) {

          const contentEl = cslEntryElem.querySelector(`.${contentClass}`)
          const numEl = cslEntryElem.querySelector(`.${numericClass}`)

          if (!contentEl || !numEl)
            return ''

          const content = contentEl.innerHTML

          const num = numEl.innerHTML
          const html = num + ' ' + wrapBiblioUrls(content)

          entryHtml = `<${entryElem} ${paragraphStyle} class="bibliography">${html}</${entryElem}>`
        } else {

          cslEntryElem
            .querySelectorAll(`.csl-indent`)
            .forEach(e => {
              if (e instanceof HTMLElement) {
                e.style.marginLeft = '1cm'
                e.style.marginTop = '0.4cm'
              }
            })

          entryHtml = `<${entryElem} ${paragraphStyle} class="bibliography">${wrapBiblioUrls(cslEntryElem.innerHTML)}</${entryElem}>`
        }

        return entryHtml
      })

  return biblioHtmlStrings
}

export const encodeBiblioMeta = (meta: BiblioMeta): string => {
  if (!meta.styleTitle)
    return null

  const options = (meta.options && JSON.stringify(meta.options)) || ''
  return meta.styleTitle + '+' + options
}

export const decodeBiblioMeta = (text: string): BiblioMeta => {

  const [styleTitle, ...optionsStrings] = text.split('+')
  const optionsString = optionsStrings.join('+')
  const options = optionsString && JSON.parse(optionsString)

  return {
    styleTitle,
    options
  }
}

const wrapBiblioUrls = (html: string): string => {

  if (!html)
    return html

  // if urls are decoded they are wrongly parsed
  html = html.replace(/&amp;/g, '&')

  const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:;%_\+.~#?&//=]*)/g
  const urlInstances = html.match(URL_REGEX)

  if (urlInstances && urlInstances.length) {
    urlInstances.forEach(url => {
      // some styles append dot char at end of biblio entry, and regex recognizes it as part of url, so it is removed
      if (url && url.charAt(url.length - 1) === '.')
        url = url.substring(0, url.length - 1)
      html = html.replace(url, `<a href="${url}">${url}</a>`)
    })
    return html
  } else {
    return html
  }
}