export const SC_BIB_TITLE = 'SmartCite Bibliography'
export const SC_CIT_TITLE = 'SmartCite Citation'

export const run = <T>(
  func: (ctx: Word.RequestContext) => Promise<T>,
  debugName?: string,
): Promise<T> =>
  new Promise((resolve, reject) => {

    const timerLabel = debugName || 'unnamed'
    const timerId = crypto.randomUUID()
    const timerName = timerLabel + ' ' + timerId
    console.log('Word run start "' + timerName + '" at ' + new Date().toISOString())

    Word
      .run(async ctx => {

        // tslint:disable-next-line:no-console
        console.time(timerName)

        const result = await func(ctx)

        resolve(result)
      })
      .catch(e => {
        console.log(e)
        if (e instanceof OfficeExtension.Error) {
          console.log('Error code and message: ' + e.toString())
          console.log(e.debugInfo)
        }
        reject(e)
      })
      .finally(() => {
         // tslint:disable-next-line:no-console
         console.timeEnd(timerName)
      })
  })

  export const isDesktopRuntime = () => {
    return window && window.top === window
  }